<template>
  <div id="meer-info" class="flex-initial w-full mb-24 px-4">
    <div class="max-w-5xl mx-auto">
      <span class="text-2xl block mb-4">Meer informatie</span>
      <p class="text-justify mb-4">
        Gemeente Tilburg is voornemens om in het westelijk buitengebied, de
        agrarische uitlopers van Stadsbos013, een industrieterrein aan te leggen
        voor grootschalige logistiek en industrie. Zij noemen het ecologisch
        werklandschap Wijkevoort. Wij vinden het verdozing van het landschap.
      </p>
      <p class="text-justify mb-4">
        Wat nu nog kleinschalig agrarisch cultuurlandschap is onder de rook van
        vliegveld GilzeRijen, waar nog boerderijen staan, koeien, herten en
        paarden in de wei lopen, verrijzen straks over een oppervlakte van 2x de
        Efteling heel veel nieuwe distributiecentra, (dc's xxl). Voor de
        productie, opslag en distributie van spullen. Heel veel spullen. In 1
        zo’n doos daar past het WillemII stadion met gemak 4x in.<br />
        En waarom? Omdat gemeente Tilburg zegt dat het goed is voor de economie,
        al die spullen. Het is goed voor de werkgelegenheid. Behalve robots en
        arbeidsmigranten werken er straks ook nog andere mensen, zeggen ze.<br />
        Omwonenden, maar ook mensen uit naastgelegen stadsdeel Reeshof, Gilze en
        Hulten strijden dapper voor het behoud van het buitengebied. Ze hebben
        zich verenigd in Stichting Reeshof aan Zet, Bewonersvereniging
        buitengebied zuidwest Tilburg en Stichting Gilze Hulten Open Landschap.
        Maar het is een oneerlijke strijd. Burgers moeten het in hun vrije tijd
        doen en zonder middelen. Dan nemen ze het op tegen een gemeente die een
        hele leger aan specialisten en juristen aan het werk heeft gezet om een
        nieuw bestemmingsplan te maken.
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "MoreInfo"
};
</script>
