<template>
  <div class="max-w-md px-4 mx-auto xl:max-w-lg">
    <span class="block mb-4 text-2xl">De petitie</span>
    <p class="block mb-8 text-justify">
      Onze petitie tegen de komst van bedrijventerrein Wijkevoort, tegen de
      verdozing van het landschap en voor het behoud van het westelijk Tilburgs
      buitengebied is 3857x ondertekend en op 1 november 2021 aangeboden aan de
      gemeenteraad van Tilburg. Vergeefse moeite. 2 weken later heeft de
      gemeenteraad het bestemmingsplan aangenomen.
    </p>
    <a
      href="https://www.tilburg.nl/actueel/gebiedsontwikkeling/wijkevoort/"
      target="_blank"
      rel="noopener noreferrer"
      class="px-6 py-4 font-bold text-white bg-red-500 rounded hover:bg-red-700"
    >
      Meer informatie over dit raadsbesluit
    </a>
  </div>
</template>

<script>
export default {
  name: "PetitieModule",
};
</script>
