<template>
  <div class="footer-wrapper">
    <div class="min-h-screen bg-blue-900 bg-opacity-70">
      <div
        class="container flex flex-col flex-wrap px-4 py-32 mx-auto text-white md:flex-row md:px-0"
      >
        <MoreInfo />
        <div class="flex-initial w-full mb-16 md:w-1/3">
          <span class="block mb-8 text-2xl">Contact</span>
          <p>Stichting Reeshof aan Zet</p>
          <p>
            <a class="text-white" href="https://reeshofaanzet.nl"
              >reeshofaanzet.nl</a
            >
          </p>
          <p>
            <a class="text-white" href="mailto:reeshofaanzet@gmail.com"
              >reeshofaanzet@gmail.com</a
            >
          </p>
          <p>
            <a
              class="text-white hover:text-red-500"
              href="https://www.facebook.com/Reeshof-aan-zet-voor-Wijkevoort-2326274674318648"
              target="_blank"
              ><svg
                xmlns="http://www.w3.org/2000/svg"
                width="32"
                height="32"
                fill="currentColor"
                class="inline m-4 bi bi-facebook"
                viewBox="0 0 16 16"
              >
                <path
                  d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951z"
                /></svg></a
            ><a
              class="text-white hover:text-red-500"
              href="https://twitter.com/aan_zet"
              target="_blank"
              ><svg
                xmlns="http://www.w3.org/2000/svg"
                width="32"
                height="32"
                fill="currentColor"
                class="inline m-4 bi bi-twitter"
                viewBox="0 0 16 16"
              >
                <path
                  d="M5.026 15c6.038 0 9.341-5.003 9.341-9.334 0-.14 0-.282-.006-.422A6.685 6.685 0 0 0 16 3.542a6.658 6.658 0 0 1-1.889.518 3.301 3.301 0 0 0 1.447-1.817 6.533 6.533 0 0 1-2.087.793A3.286 3.286 0 0 0 7.875 6.03a9.325 9.325 0 0 1-6.767-3.429 3.289 3.289 0 0 0 1.018 4.382A3.323 3.323 0 0 1 .64 6.575v.045a3.288 3.288 0 0 0 2.632 3.218 3.203 3.203 0 0 1-.865.115 3.23 3.23 0 0 1-.614-.057 3.283 3.283 0 0 0 3.067 2.277A6.588 6.588 0 0 1 .78 13.58a6.32 6.32 0 0 1-.78-.045A9.344 9.344 0 0 0 5.026 15z"
                /></svg
            ></a>
          </p>
        </div>
        <div class="flex-initial w-full mb-16 md:w-1/3">
          <span class="block mb-8 text-2xl">Donaties</span>
          <p>
            Om de volgende stap richting Raad van State mogelijk te maken zijn
            we afhankelijk van donaties. Finaciele bijdrages kunnen worden
            overgemaakt naar:<br /><br />
            Stichting Reeshof aan Zet<br />
            NL25 INGB 0009 532 730<br />
            o.v.v. Rechtsgang Wijkevoort<br />
            Of via
            <a
              href="https://www.ing.nl/particulier/betaalverzoek/index.html?trxid=NhwSoAuaCOroNsIUUwren38GfrAF2X13"
              target="_blank"
              class="font-semibold text-red-500 underline"
              rel="noopener noreferrer"
              >deze betaallink</a
            >.
          </p>
        </div>
        <div id="nieuwsbrief" class="flex-initial w-full mb-16 md:w-1/3">
          <h2 class="mb-8 text-2xl">Schrijf je in voor de nieuwsbrief</h2>
          <form
            v-if="signup !== true"
            method="post"
            class="m-4"
            action="https://reeshof-aan-zet.email-provider.nl/subscribe/post/index.php"
            accept-charset="utf-8"
          >
            <input
              type="hidden"
              name="next"
              value="https://reeshofaanzet.nl/?s=true#nieuwsbrief"
            />
            <input type="hidden" name="a" value="acpp3zztaa" />
            <input type="hidden" name="l" value="pnswbxmd5i" />
            <input
              type="email"
              name="09MBtQzDUI"
              class="w-full p-4 mr-0 text-gray-800 bg-white border-t border-l border-r border-gray-200 rounded-t-lg"
              placeholder="uw@emailadres.nl"
              id="id-09MBtQzDUI"
              required
            />
            <input type="hidden" name="SkCwJsTYQh" id="id-SkCwJsTYQh" />
            <input type="hidden" name="EUZbMt5cZn" id="id-EUZbMt5cZn" />
            <input
              autocomplete="new-password"
              type="email"
              id="email"
              name="email"
              placeholder="Your e-mail here"
              style="position: absolute; top: -9999px; left: -9999px"
            />
            <input
              type="submit"
              class="w-full p-4 px-8 font-bold text-white uppercase bg-red-500 border-b border-l border-r border-red-500 rounded-b-lg cursor-pointer hover:bg-red-700 hover:border-red-700"
              value="Aanmelden"
            />
          </form>
          <p v-else>Bedankt voor uw aanmelding!</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MoreInfo from "./MoreInfo";
export default {
  name: "ContentSecond",
  components: {
    MoreInfo,
  },
  data: () => {
    return {
      signup: false,
    };
  },
  methods: {
    checkSignup() {
      if (window.location.search == "?s=true") {
        this.signup = true;
      }
      return;
    },
  },
  beforeMount() {
    this.checkSignup();
  },
};
</script>
