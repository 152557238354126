<template>
  <table v-if="list.length > 0" class="bg-white mx-auto w-full md:w-3/5">
    <tr class="border-b-2 border-gray-500">
      <th class="bg-blue-100 border text-left pl-2 md:px-8 py-4">
        Bestandsnaam
      </th>
      <th class="bg-blue-100 border text-center pl-2 md:px-8 py-4">Link</th>
    </tr>
    <tr v-for="item in list" :key="item.index">
      <th class="bg-gray-200 border text-left font-light pl-2 md:px-8 py-4">
        {{ item.name }}
      </th>

      <th class="bg-gray-200 border text-center px-2 md:px-8 py-4">
        <a
          :href="`${item.url}`"
          target="_blank"
          class="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-2 md:px-4 rounded"
          >Download</a
        >
      </th>
    </tr>
  </table>
  <p v-else>Er zijn momenteel geen bestanden beschikbaar.</p>
</template>

<script>
  export default {
    name: "DownloadList",
    props: {
      list: Array,
    },
  };
</script>
