<template>
  <div id="petitie" class="min-h-screen bg-gray-50">
    <div
      class="container flex flex-col flex-wrap px-4 py-32 mx-auto md:flex-row md:px-0"
    >
      <div class="flex-initial w-full mb-32 md:w-1/2">
        <div class="max-w-md mx-auto xl:max-w-lg">
          <h2 class="mb-4 text-2xl">Plan Wijkevoort</h2>
          <p class="mb-4 text-justify">
            Gemeente Tilburg is voornemens om in het westelijk buitengebied, de
            agrarische uitlopers van Standsbos013, een industrieterrein aan te
            leggen voor grootschalige logistiek en industrie. Zij noemen het
            ecologisch werklandschap Wijkevoort. Wij vinden het verdozing van
            het landschap.
          </p>
          <a
            href="#meer-info"
            class="block px-4 py-2 mb-2 font-bold text-white bg-green-400 rounded hover:bg-green-700"
          >
            Meer informatie over Plan Wijkevoort
          </a>
        </div>
      </div>
      <div class="flex-initial w-full mb-32 md:w-1/2">
        <PetitieModule />
      </div>

      <div class="flex-initial w-full mb-24">
        <h2 class="mb-4 text-2xl">Lees: 013 zorgen</h2>
        <DownloadList
          v-bind:list="[
            {
              name: '1. Milieucategorie 4.2',
              url: '/downloads/1_milieucategorie_4.2.pdf',
            },
            { name: '2. Verdozing', url: '/downloads/2_verdozing.pdf' },
            { name: '3. Vrachtverkeer', url: '/downloads/3_vrachtverkeer.pdf' },
            {
              name: '4. Luchtvervuiling',
              url: '/downloads/4_luchtvervuiling.pdf',
            },
            {
              name: '5. Geluidsoverlast',
              url: '/downloads/5_geluidsoverlast.pdf',
            },
            { name: '6. Leefomgeving', url: '/downloads/6_leefomgeving.pdf' },
            {
              name: '7. Arbeidsmigratenvraagstuk',
              url: '/downloads/7_arbeidsmigrantenvraagstuk.pdf',
            },
            {
              name: '8. Lastenverdeling',
              url: '/downloads/8_lastenverdeling.pdf',
            },
            {
              name: '9. Biodiversiteit',
              url: '/downloads/9_biodiversiteit.pdf',
            },
            {
              name: '10. Landschapspijn',
              url: '/downloads/10_landschapspijn.pdf',
            },
            {
              name: '11. Overheidsoptreden',
              url: '/downloads/11_overheidsoptreden.pdf',
            },
            { name: '12. Hittestress', url: '/downloads/12_hittestress.pdf' },
            { name: '13. Toekomst', url: '/downloads/13_toekomst.pdf' },
            {
              name: 'Thematisch beantwoording van onze 013 zorgen',
              url: '/downloads/thematische_beantwoording_013_zorgen.pdf',
            },
          ]"
        />
      </div>
      <div class="flex-initial w-full mb-16">
        <h2 class="mb-4 text-2xl">Nieuwsbrief archief</h2>
        <DownloadList
          v-bind:list="[
            {
              name: '1. Heel Tilburg omsingeld door industrie',
              url: '/downloads/heeltilburgomsingelddoorindustrie.pdf',
            },
            {
              name: '2. Oproep om Zienswijzen te schrijven',
              url: '/downloads/oproep_om_zienswijzen_te_schrijven.pdf',
            },
            {
              name: '3. Hoe schrijf je zienswijzen',
              url: '/downloads/3_hoe_schrijf_je_zienswijzen.pdf',
            },
            {
              name: '4. Wat gebeurt er na de zienswijzen',
              url: '/downloads/4_wat_gebeurt_er_na_de_zienswijzen.pdf',
            },
            {
              name: '5. Wat zijn onze 013 zorgen?',
              url: '/downloads/nieuwsbrief_5.pdf',
            },
            {
              name: '6. Nu is de gemeente aan zet',
              url: '/downloads/nieuwsbrief_6.pdf',
            },
            {
              name: '7. Het bestemmingsplan komt er aan',
              url: '/downloads/nieuwsbrief_7.pdf',
            },
            {
              name: '8. Het bestuur stelt zich voor',
              url: '/downloads/nieuwsbrief_8.pdf',
            },
            {
              name: '9. De kogel is door de kerk',
              url: '/downloads/nieuwsbrief_9.pdf',
            },
            {
              name: '9a. Donaties nodig om door te gaan',
              url: '/downloads/nieuwsbrief_9a.pdf',
            },
            {
              name: '10. De feestdagen staan voor de deur',
              url: '/downloads/10_de_feestdagen_staan_voor_de_deur.pdf',
            },
            {
              name: '11. De verkiezingen',
              url: '/downloads/11_de_verkiezingen.pdf',
            },
            {
              name: '12. Wat zijn de ontwikkelingen geweest dit jaar',
              url: '/downloads/12_wat_zijn_de_ontwikkelingen_geweest_dit_jaar.pdf',
            },
          ]"
        />
      </div>
      <div class="flex-initial w-full mb-16">
        <h2 class="mb-4 text-2xl">Overige documenten</h2>
        <DownloadList
          v-bind:list="[
            {
              name: 'Artikel 40 - Vragen over stikstof',
              url: '/downloads/art40_vragen_stikstof.pdf',
            },
            {
              name: 'Antwoord n.a.v. de vragen over stikstof',
              url: '/downloads/beantwoording_art_40_vragen_stikstof.pdf',
            },
            {
              name: 'Bewonersbrief n.a.v. het advies van de commissie MER (juni 2021)',
              url: '/downloads/bewonersbrief_uitleg_over_het_advies_MER_juni_2021.jpg',
            },
          ]"
        />
      </div>
    </div>
  </div>
</template>

<script>
import PetitieModule from "./PetitieModule";
import DownloadList from "./DownloadList";
export default {
  name: "ContentFirst",
  components: {
    PetitieModule,
    DownloadList,
  },
  methods: {
    printPDF(pdfUrl) {
      var w = window.open(pdfUrl);
      w.print();
    },
  },
};
</script>
