<template>
  <div class="bg-gray-700">
    <div class="container mx-auto px-4 sm:px-0 py-2">
      <a
        class="text-white"
        href="https://www.rds-online.nl/duurzaamheid/"
        target="_blank"
        rel="noopener noreferrer"
        >Duurzaam online met RDS-Online</a
      >
    </div>
  </div>
  <!-- Socials en Nieuwsbrief -->
</template>

<script>
  export default {
    name: "Footer",
  };
</script>
