<template>
  <div id="header" class="min-h-screen">
    <div class="bg-opacity-30 bg-yellow-100 min-h-screen">
      <div class="container mx-auto px-4 md:px-0 pt-4 pb-8">
        <div id="topbar" class="text-left w-full animate-slowfade">
          <h1 class="font-bold text-xl">Reeshof aan Zet voor Wijkevoort</h1>
          <span class="text-s text-justify"
            >Voor een aantrekkelijk, kleinschalig, waardevol agrarisch
            cultuurlandschap in Tilburg</span
          >
        </div>
        <div id="mainhead" class="animate-delayedfade">
          <div class="mt-48">
            <span class="font-medium text-6xl md:text-8xl"
              >Reeshof aan Zet</span
            >
          </div>
          <div
            class="w-full flex flex-col flex-wrap md:flex-row mx-auto justify-center mt-24"
          >
            <a
              href="#petitie"
              class="bg-red-500 hover:bg-red-700 text-white text-2xl font-bold py-4 px-8 rounded m-4"
            >
              Teken de petitie
            </a>
            <a
              href="#nieuwsbrief"
              class="bg-green-400 hover:bg-green-700 text-white text-2xl font-bold py-4 px-8 rounded m-4"
            >
              Schrijf je in voor de nieuwsbrief
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: "Header",
    created() {
      window.addEventListener("scroll", this.handleScroll);
    },
    unmounted() {
      window.removeEventListener("scroll", this.handleScroll);
    },
    methods: {
      handleScroll() {
        const topBar = document.getElementById("topbar");
        const mainHead = document.getElementById("mainhead");
        topBar.classList.remove("animate-slowfade");
        mainHead.classList.remove("animate-delayedfade");
      },
    },
  };
</script>
