<template>
  <div>
  <Header />
  <ContentFirst />
  <ContentSecond />
  <Footer />
  </div>
</template>

<script>
  import Header from "./components/Header.vue";
  import ContentFirst from "./components/ContentFirst.vue";
  import ContentSecond from "./components/ContentSecond.vue";
  import Footer from "./components/Footer.vue";

  export default {
    name: "App",
    components: {
      Header,
      ContentFirst,
      ContentSecond,
      Footer,
    },
  };
</script>
